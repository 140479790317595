import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMoveRequestById } from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import { io } from "socket.io-client";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Arrow from "../../assets/images/Arrow.png";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import VideoPopup from "../../components/modals/videoPopup";
import LogoHeader from "../../components/logo-header/logoHeader";

const VideoGuidelines = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const colorBadge = {
    marginTop: 2,
    fontSize: 14,
    fontWeight: 800,
    backgroundColor: `${theme.palette.primary.main}`,
    color: "#FFFFFF",
    width: "80px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  // const [jwtToken, setJwtToken] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const thisUrl = process.env.REACT_APP_API_URL || "";
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("flowStartedOn");
  }, []);
  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("notifyToRedirect", (requestData: any) => {
      setShouldRedirect(requestData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    if (shouldRedirect === true) {
      navigate(`/AddressInfo/${thisMoveRequestId}`);
    }
  }, [shouldRedirect]);
  const sessionId = moveRequestId;
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log("move req in guidelines=>", thisMoveRequest.canonical_id);
        setThisMoveRequestId(thisMoveRequest.canonical_id);
        const token = localStorage.getItem("jwtToken");
        // setJwtToken(token || "");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);

  useEffect(() => {
    setIsPageLoading(true);
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1300);
  }, []);
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    console.log(localURL, "--LOCAL");
  }, [url]);
  // const prodURL = `https://production.wemoveai.com/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  // const urlForScanning = `${url}/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;
  // const localURL = `http://localhost:3000/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}&sessionId=${sessionId}`;

  const urlForScanning = `${url}/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;
  const localURL = `http://localhost:3000/upload-inventory/${moveRequestId}?&sessionId=${sessionId}`;

  

    ReactGA.send({
      hitType:'pageview',
      page:'/inventory-guidelines/:moveRequestId',
      title:'Video GuideLines Page'
    })
    
  

  const [open, setOpen] = useState(true);
  const style = {
    width: "100%",
    maxWidth: "600px",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    p: 1,
    "@media (max-width: 600px)": {
      width: "90%", // For mobile devices
    },
    "@media (max-width: 900px)": {
      width: "95%", // For tablet devices
    },
  };
  return (
    <>
      <LogoHeader />
      <Grid
        container
        sx={{
          padding: isMobile ? 2 : 5,
        }}
      >
        <Grid item xs={12}>
          <Stack
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              mt={1}
              fontSize={28}
              fontWeight={400}
              letterSpacing={1.5}
              textAlign={"center"}
              color={theme.palette.primary.main}
              // fontFamily={"times-regular"}
            >
              Inventory Capture Made Easy
            </Typography>
            <Box alignItems={"center"} sx={style}>
              <video width="100%" height="auto" controls autoPlay muted loop>
                <source
                  src="https://we-move-production.s3.us-east-1.amazonaws.com/Inventory+Instructions.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Stack>
          {!isMobile ? (
            <Grid item xs={12} sm={6} marginX={"auto"}>
             <Box display={"flex"} justifyContent={"center"}> <h3>Scan the QR with your phone</h3></Box>
              <Box marginTop={2} display={"flex"} justifyContent={"center"}>
                
                <QRCode
                  size={200}
                  value={urlForScanning}
                  viewBox={`0 0 256 256`}
                />
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          <Box
            alignItems="center"
            style={{
              marginTop: "3% !important",
              justifyContent: "space-between",
              padding: "0 20px",
              maxWidth: "800px",
              width: "100%",
              margin: "3% auto",
            }}
          >
            <Typography>
              Capture your inventory quickly and easily! Simply take one to two
              photos per room—no need to open cabinets or drawers. For best
              results, use panoramic shots from the center or take two photos
              from different angles to capture the whole room. You can adjust
              items later when reviewing your inventory.
            </Typography>
            <Typography mt={2}>
              Just name the room, snap the pictures, and you're done—all with
              your phone camera!
            </Typography>
            <ul>
              <li>
                <Typography>
                  To get started, scan the QR code with your phone to take
                  photos of each room or give permission.
                </Typography>
              </li>
              <li>
                <Typography>
                  You can switch between your phone and computer anytime. Just
                  follow the steps provided.
                </Typography>
              </li>
            </ul>
          </Box>

          <Box mt={2} display={"flex"} justifyContent={"center"}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  // `/upload-inventory/${thisMoveRequestId}?tokenvalue=${jwtToken}`
                  `/upload-inventory/${thisMoveRequestId}`
                );
              }}
              sx={{
                background: theme.palette.primary.main,
                paddingTop: "12px",
                paddingBottom: "12px",
                borderRadius: 10,
                fontFamily: "monospace",
                fontWeight: 550,
                letterSpacing: 1.5,
                width: 450,
                "&:hover": {
                  backgroundColor: "#000000", // Hover background color
                  color: "#FDFCFD", // Hover text color
                },
              }}
            >
              Give permission & Continue
            </Button>
          </Box>

          <Grid item xs={12} p={4}></Grid>

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default VideoGuidelines;
