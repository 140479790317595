import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import Stairs from "../../assets/images/StairsIcon.png";
import Elevator from "../../assets/images/Elevator.png";
import NotAvailable from "../../assets/images/N:A-Icon.png";
import SecurityIcon from "@mui/icons-material/Security";
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import placesApiKey from "../utils/Google-Places-API-Key";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomCircularProgress } from "../circularProgress";
import { NeedHelp } from "../needHelp";
import ReactGA from 'react-ga4'


const PLACES_API_KEY = placesApiKey;

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: "100%",
  },
];

const storageSizeDropDown = [
  {
    label: "5x5x8",
    value: 200,
  },
  {
    label: "5x10x8",
    value: 400,
  },
  {
    label: "5x15x8",
    value: 600,
  },
  {
    label: "10x10x8",
    value: 800,
  },
  {
    label: "10x15x8",
    value: 1200,
  },
  {
    label: "10x20x8",
    value: 1600,
  },
  {
    label: "10x25x8",
    value: 2000,
  },
  {
    label: "10x30x8",
    value: 2400,
  },
  {
    label: "20x20x8",
    value: 3200,
  },
];

const notSeelectedButtonStyle = {
  width: 150,
  height: 120,
  backgroundColor: "#F2F2F4 !important",
  color: "#262626",
  fontSize: 12,
  fontWeight: 550,
  border: "1px solid #0000001A",
  borderRadius: "16px",
};

const selectedButtonStyle = {
  width: 150,
  height: 120,
  backgroundColor: `#002496 !important`,
  color: "#FDFCFD",
  fontSize: 12,
  fontWeight: 550,
  borderRadius: "16px",
};

const StorageOnlyModal = ({
  storageOnlyModalVisible,
  setStorageOnlyModalVisible,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [storageSize, setStorageSize] = useState<any>("");
  const [storageAddress, setStorageAddress] = useState("");
  const [storageZipCode, setStorageZipCode] = useState("");
  const [storageFilled, setStorageFilled] = useState<any>(70);
  const [isElevator, setIsElevator] = useState("");
  const [destinationFloor, setDestinationFloor] = useState<any>(null);

  const [formError, setFormError] = useState("");

  const handleZipCodeChange = (event: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 5;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setStorageZipCode(inputValue);
    event.target.value = inputValue;
  };

  const handleInputNumber = (event: any, setNumber: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 7;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setNumber(inputValue);
    event.target.value = inputValue;
  };

  const handleClose = () => setStorageOnlyModalVisible(true);

  const valueText = (value: number) => {
    return `${value}%`;
  };

  const handleStorageFilled = (event: any) => {
    setStorageFilled(event.target.value);
  };

  const navigateToLocationInfo = async () => {
    if (storageSize === "") {
      setFormError("Please select a Storage size.");
      return;
    }

    if (storageAddress === "") {
      setFormError("Please enter your Address.");
      return;
    }

    if (storageZipCode === "") {
      setFormError("Please enter your Zip code.");
      return;
    }

    if (isElevator === "") {
      setFormError("Please select if Elevator or Stairs would be needed.");
      return;
    }

    if (destinationFloor === null) {
      setFormError("Please enter floor number.");
      return;
    }

    setFormError("");

    ReactGA.send({
      hitType:'pageview',
      page:"/start-move/storage-only",
      title:`Address selected storage only`
    })


    await createMoveRequest({
      id: thisMoveRequest.id,
      storage: {
        storage_size: Number(storageSize.target.value.value),
        storage_filled: Number(storageFilled),
        zip_code: storageZipCode,
        address: storageAddress,
        floor_no:
          isElevator === "not-applicable" ? 0 : Number(destinationFloor),
        is_elevator_accessible: isElevator === "elevator" ? true : false,
        are_stairs_present: isElevator === "stairs" ? true : false,
      },
    });
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;
      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleStorageAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setStorageZipCode(zip);
    setStorageAddress(completeAddress);
  };
  console.log("got rendered");
  return (
    <Box
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
        height: isMobile ? "90vh" : "auto",
        p: { xs: 2, sm: 4 }, // Responsive padding for small screens
        mt: { xs: 3, sm: 5 },
        border: "1px solid #0000001A",
        borderRadius: "8px",
        overflow: "auto",
        backgroundColor: "#FBFBFB",
      }}
    >
      <NeedHelp />
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      )}

      {/* Header */}
      <Stack
        sx={{
          alignItems: isMobile ? "center" : "flex-start",
          overflow: "auto",
          mb: 3, // Margin bottom for spacing
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ mb: 2 }}
        >
          <Stack>
            <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
              Storage Address
            </Typography>
            <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
              Fill in all info correctly
            </Typography>
          </Stack>
          <CustomCircularProgress value={3} total={10} />
        </Stack>
      </Stack>

      {/* Content */}
      <Grid
        container
        spacing={2}
        flexDirection={isMobile ? "column-reverse" : undefined}
      >
        <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
          {/* Storage Size Selector */}
          <Typography mt={1} mb={1} fontWeight={500} fontSize={16}>
            Storage Size
          </Typography>
          <Stack alignItems={"center"} direction={"row"} spacing={1}>
            <FormControl sx={{ minWidth: { xs: 150, sm: 150 } }} size="small">
              <InputLabel id="demo-select-small-label">
                {storageSize !== ""
                  ? storageSize.target.value.label
                  : "Storage Size"}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={""}
                label="Storage Size"
                onChange={(e) => {
                  setStorageSize(e);
                }}
                required
              >
                {storageSizeDropDown.map((size: any) => (
                  <MenuItem key={size.value} value={size}>
                    {size.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
          {/* Slider for Storage Filled */}
          <Typography fontSize={14}>How full is your storage unit?</Typography>
          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography fontStyle={"italic"} fontSize={12} color={"#262626"}>
              Drag to adjust
            </Typography>
            <Box sx={{ width: { xs: 230, sm: 450 } }}>
              <Slider
                sx={{ width: { xs: 230, sm: 450 } }}
                aria-label="Always visible"
                defaultValue={70}
                getAriaValueText={valueText}
                step={10}
                onChange={(val) => {
                  handleStorageFilled(val);
                }}
                marks={marks}
                valueLabelDisplay="on"
              />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
          {/* Address Input */}
          <Typography fontSize={14} fontWeight={500}>
            What is the storage facility address?
          </Typography>
          <Stack spacing={1} mt={2} direction={isMobile ? "column" : "row"}>
            <GooglePlacesAutocomplete
              apiOptions={{ language: "en" }}
              minLengthAutocomplete={1}
              apiKey={PLACES_API_KEY}
              debounce={500}
              selectProps={{
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: "#000000",
                    height: "100%",
                    width: 150,
                    borderRadius: "8px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                },
                onChange: handleStorageAddress,
                placeholder: "Enter Address",
              }}
            />

            <TextField
              variant="outlined"
              type="text"
              label="Zip Code"
              size="small"
              value={storageZipCode}
              onChange={(e) => handleZipCodeChange(e)}
              inputProps={{
                style: {
                  background: "#5A7BFC14",
                  borderRadius: "8px",
                },
              }}
              sx={{
                mt: 1,
                width: { xs: "100%", sm: 220 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#5A7BFC59",
                    borderWidth: "1.43",
                    borderRadius: "8px",
                  },
                },
              }}
              required
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
          {/* Stairs or Elevator */}
          <Typography mt={1} fontWeight={400} fontSize={14}>
            Stairs or an Elevator needed?
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={14}
            sx={{ fontStyle: "italic" }}
          >
            Choose one
          </Typography>
          <Stack mt={2} direction="row" spacing={1}>
            <Button
              onClick={() => {
                setIsElevator("stairs");
                setDestinationFloor(null);
              }}
              sx={
                isElevator === "stairs"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Stairs}
                  style={{
                    filter:
                      isElevator === "stairs"
                        ? "none"
                        : "invert(100%) brightness(2)",
                    width: 50,
                    height: 50,
                  }}
                  alt="stairs"
                />
                <Typography
                  fontSize={12}
                  color={isElevator === "stairs" ? "#FFFFFF" : "#000000"}
                >
                  Stairs
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsElevator("elevator");
                setDestinationFloor(null);
              }}
              sx={
                isElevator === "elevator"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Elevator}
                  style={{
                    filter:
                      isElevator === "elevator"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="elevator"
                />
                <Typography
                  fontSize={12}
                  color={isElevator === "elevator" ? "#FFFFFF" : "#000000"}
                >
                  Elevator
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsElevator("not-applicable");
                setDestinationFloor(0);
              }}
              sx={
                isElevator === "not-applicable"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={NotAvailable}
                  style={{
                    filter:
                      isElevator === "not-applicable"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="not-applicable"
                />
                <Typography
                  fontSize={12}
                  color={
                    isElevator === "not-applicable" ? "#FFFFFF" : "#000000"
                  }
                >
                  Not Applicable
                </Typography>
              </Stack>
            </Button>
          </Stack>
          {isElevator !== "not-applicable" && (
            <>
              <Typography
                fontSize={14}
                mt={2}
                fontWeight={400}
                color={"#262626"}
              >
                What floor is your storage on?
              </Typography>

              <TextField
                variant="outlined"
                value={destinationFloor || ""}
                size="small"
                onChange={(event) => {
                  handleInputNumber(event, setDestinationFloor);
                }}
                inputProps={{
                  style: {
                    background: "#5A7BFC14",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  marginTop: 1,
                  width: 220,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5A7BFC59",
                      borderWidth: "1.43",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </>
          )}
        </Grid>
      </Grid>

      {/* Security Info */}
      <Stack
        p={1}
        spacing={1}
        mt={5}
        width={"90%"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
      >
        <SecurityIcon fontSize="small" sx={{ color: "#0FB69D" }} />
        <Typography fontSize={10}>
          Your info is safe with us. We need details to create the perfect plan
          for you.
        </Typography>
      </Stack>

      {/* Error and Buttons */}
      <Box p={3}>
        {formError && <Alert severity="error">{formError}</Alert>}
      </Box>

      <Stack p={4} direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() => {
            setMoveType("");
            setOutOfStateMoveModalVisible(true);
            setStorageOnlyModalVisible(false);
          }}
          sx={{
            width: 120,
            height: 45,
            backgroundColor: "#E9E9EA",
            color: "#808080",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Back
        </Button>
        <Button
          onClick={navigateToLocationInfo}
          sx={{
            width: 180,
            height: 45,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};

export default StorageOnlyModal;
