import { ActionTypes } from "../constants/action-types";
import { apiCall } from "../../apis/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createThieMoveRequest = createAsyncThunk(
  ActionTypes.CREATE_MOVE_REQUEST,
  async (params: any, { rejectWithValue }) => {
    try {
      const { body, consumerId } = params;
      const res = await apiCall(
        `/move-request/createMoveRequest/${consumerId}`,
        "post",
        body
      );
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllMoveRequestsByConsumerID = createAsyncThunk(
  ActionTypes.GET_ALL_MOVE_REQUESTS_BY_CONSUMER_ID,
  async (consumerCanonicalId: string, { rejectWithValue }) => {
    try {
      const res = await apiCall(
        `/move-request/getAllMoveRequests/${consumerCanonicalId}`,
        "get"
      );
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMoveRequestById = createAsyncThunk(
  ActionTypes.GET_MOVE_REQUEST_BY_ID,
  async (moveRequestId: string, { rejectWithValue }) => {
    try {
      const res = await apiCall(`/move-request/${moveRequestId}`, "get");
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateMoveRequestById = createAsyncThunk(
  ActionTypes.UPDATE_MOVE_REQUEST_BY_ID,
  async (body: any, { rejectWithValue }) => {
    try {
      const res = await apiCall(
        `/move-request/updateMoveRequest`,
        "patch",
        body
      );
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCheckoutSession = createAsyncThunk(
  ActionTypes.CREATE_CHECKOUT_SESSION,
  async (body: any, { rejectWithValue }) => {
    try {
      const res = await apiCall(`/move-request/checkoutSession`, "post", body);
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const shouldAutoRedirect = createAsyncThunk(
  ActionTypes.AUTO_REDIRECT,
  async (_, { rejectWithValue }) => {
    try {
      const res = await apiCall(`/move-request/auto-redirect`, "post");
      return res;
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const attachUserWithMoveRequest = createAsyncThunk(
  ActionTypes.ATTACH_MOVE_REQEUEST_WITH_USER,
  async (params: any, { rejectWithValue }) => {
    console.log("here in the redux=>", params);
    const { moveRequestId, userId } = params;
    try {
      const res = await apiCall(
        `/move-request/update-move-with-user/${moveRequestId}`,
        "patch",
        { userId }
      );
      return res;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendDataToSuperMove = createAsyncThunk(
  ActionTypes.SEND_DATA_TO_SUPER_MOVE,
  async (moveRequestId: number, { rejectWithValue }) => {
    try {
      const res = await apiCall(
        `/move-request/pushToSuperMove/${moveRequestId}`,
        "post"
      );
      return res;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendDataToGranotMove = createAsyncThunk(
  ActionTypes.SEND_DATA_TO_GRANOT_MOVE,
  async (moveRequestId: number, { rejectWithValue }) => {
    try {
      const res = await apiCall(
        `/move-request/pushToGranotMove/${moveRequestId}`,
        "post"
      );
      return res;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);