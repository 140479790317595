import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import landingImage from "../../assets/images/Two men carrying sofa 3.png";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import ProgressBar from "../../components/progressBar/progressBar";
import NavBar from "../../components/navbar/navBar";
import { setKey, setLanguage, geocode, RequestType } from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import placesApiKey from "../../components/utils/Google-Places-API-Key";
import { getMoveDistance } from "../../redux/actions/consumer";
import axios from "axios";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { NeedHelp } from "../../components/needHelp";
import { CustomCircularProgress } from "../../components/circularProgress";
import LogoHeader from "../../components/logo-header/logoHeader";

setKey("AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8");
setLanguage("en");
const PLACES_API_KEY = placesApiKey;

const buttonContainer = {
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  marginBottom: 2,
};

const notSeelectedButtonStyle = {
  width: 130,
  height: 40,
  color: "#5859DF",
  fontSize: 12,
  fontWeight: 550,
  border: "1px solid #6552FF",
};

const selectedButtonStyle = {
  width: 130,
  height: 40,
  backgroundColor: "#5858E0 !important",
  color: "#FDFCFD",
  fontSize: 12,
  fontWeight: 550,
  border: "1px solid #6552FF",
};

const AddressInfo = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");
  const currentTenant = process.env.REACT_APP_TENANT;
 

  
    ReactGA.send({
      hitType: "pageview",
      page: "/AddressInfo/:moveRequestId",
      title: "Move out Address information is given",
    });
  

  const theme = useTheme();
  const acceptButton = {
    width: 150,
    height: 50,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 550,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState(null);
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  const [isAdditionalStops, setIsAdditionalStops] = useState(false);
  const [allAddresses, setAllAddresses] = useState<any>([]);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [additionalStops, setAdditionalStops] = useState([""]);
  const [pickupAddress, setPickupAddress] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [formError, setFormError] = useState("");

  const handleAddMore = () => {
    if (additionalStops[additionalStops.length - 1] !== "") {
      setAdditionalStops([...additionalStops, ""]);
    }
  };

  const handleChange = async (index: any, event: any) => {
    const newStops = [...additionalStops];
    newStops[index] = event.value.description;
    const zip = await fetchZipCodeForAddress(event.value.description);
    const completeAddress = event.value.description.toString() + ", " + zip;
    setAdditionalStops(newStops);
    const addressObject = {
      complete_address: completeAddress,
      stiars_present: null,
      is_elevator_accessible: null,
      no_of_flights: 0,
      floor_no: 0,
    };
    setAllAddresses((prevAdd: any) => [...prevAdd, addressObject]);
  };

  const navigateToLastLeft = (moveRequest: any) => {
    if (!moveRequest.delivery_details) {
      navigate(`/AddressInfo/${moveRequest.canonical_id}`);
    } else {
      if (moveRequest.delivery_details?.delivery_addresses?.length === 0) {
        navigate(`/AddressInfo/${moveRequest.canonical_id}`);
      } else {
        if (
          moveRequest.delivery_details?.delivery_addresses[0].stiars_present ===
            null ||
          moveRequest.delivery_details?.delivery_addresses[0]
            .is_elevator_accessible === null
        ) {
          navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
        } else {
          if (
            moveRequest.pickup_date_from === null &&
            moveRequest.pickup_date_to === null
          ) {
            navigate(`/PickupDate/${moveRequest.canonical_id}`);
          } else {
            if (moveRequest.delivery_details.open_location === null) {
              navigate(`/TruckInfo/${moveRequest.canonical_id}`);
            } else {
              if (moveRequest.items?.length === 0) {
                navigate(`/itemsInfo/${moveRequest.canonical_id}`);
              } else {
                if (
                  moveRequest.delivery_details?.packagaing_required === null
                ) {
                  navigate(`/packages/${moveRequest.canonical_id}`);
                } else {
                  navigate(`/move-summary/${moveRequest.canonical_id}`);
                }
              }
            }
          }
        }
      }
    }
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setThisMoveRequestId(thisMoveRequest.id);
        setMoveRequest(thisMoveRequest);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateThisMoveRequest = (body: any) => {
    setIsPageLoading(true);
    const params = {
      id: thisMoveRequestId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
        setTimeout(() => {
          setIsPageLoading(false);
          navigate(`/DeliveryLocationInfo/${thisMoveRequest.canonical_id}`);
          // navigateToLastLeft(thisMoveRequest);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;
      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error fetching ZIP code:", error);
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setDeliveryAddress(completeAddress);
    const firstAddressObject = {
      complete_address: completeAddress,
      stiars_present: null,
      is_elevator_accessible: null,
      no_of_flights: 0,
      floor_no: 0,
    };
    setAllAddresses((prevAdd: any) => [...prevAdd, firstAddressObject]);
  };

  const calculateMoveDistance = () => {
    const body = {
      pickup_address: pickupAddress,
      delivery_address: deliveryAddress,
      moveRequestId: thisMoveRequestId,
    };
    dispatch<any>(getMoveDistance(body))
      .then(unwrapResult)
      .then((res: any) => {})
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq.home_address !== null) {
      setPickupAddress(moveReq.home_address);
    }
    if (moveReq.apartment !== null) {
      setPickupAddress(moveReq.apartment?.apt_address);
    }
    if (moveReq.storage !== null) {
      setPickupAddress(moveReq.storage?.address);
    }
    if (moveReq.combo_home_storage !== null) {
      setPickupAddress(moveReq.combo_home_storage?.home_address.toString());
    }
    if (moveReq.combo_apartment_storage !== null) {
      setPickupAddress(
        moveReq.combo_apartment_storage?.apartment?.apt_address.toString()
      );
    }
  };

  useEffect(() => {
    fetchMoveRequestById(moveRequestId);
  }, []);

  useEffect(() => {
    if (moveRequest) {
      checkFromAddress(moveRequest);
    }
  }, [moveRequest]);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <LogoHeader />
      <Grid container>
        <Grid item xs={12} md={8} p={2} marginX={"auto"}>
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "60vh",
              p: { xs: 2, sm: 4 }, // Responsive padding for small screens
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <NeedHelp />
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Delivery Address
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Fill in all info correctly
                  </Typography>
                </Stack>
                <CustomCircularProgress value={5} total={8} />
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Typography fontSize="16px" fontWeight={700} color={"#262626"}>
                While Your Quote is being generated, Lets get your
                move-to-location?
              </Typography>

              <Typography
                mt={3}
                fontSize="14px"
                fontWeight={500}
                color={"#797979"}
              >
                Enter Location
              </Typography>

              <GooglePlacesAutocomplete
                apiOptions={{
                  language: "en",
                }}
                minLengthAutocomplete={1}
                apiKey={PLACES_API_KEY}
                debounce={500}
                selectProps={{
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: "#000000",
                      height: "100%",
                      borderRadius: "8px",
                    }),
                    option: (provided) => ({
                      ...provided,
                      color: "#808080",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#808080",
                    }),
                  },
                  onChange: handleAddress,
                  placeholder: "Enter Address",
                }}
              />

              {isAdditionalStops && (
                <>
                  {additionalStops.map((stop, index) => (
                    <Box mt={3} key={index}>
                      <Typography
                        fontSize="14px"
                        fontWeight={600}
                        color="#797979"
                      >
                        {`Add Address ${index + 1}`}
                      </Typography>

                      <GooglePlacesAutocomplete
                        apiOptions={{
                          language: "en",
                        }}
                        minLengthAutocomplete={1}
                        apiKey={PLACES_API_KEY}
                        debounce={1000}
                        selectProps={{
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              color: "#000000",
                              height: "100%",
                              borderRadius: "8px",
                            }),
                            option: (provided) => ({
                              ...provided,
                              color: "#808080",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "#808080",
                            }),
                          },
                          onChange: (event) => {
                            handleChange(index, event);
                          },
                          placeholder: "Enter Address",
                        }}
                      />
                    </Box>
                  ))}

                  <Box mt={3} display="flex" justifyContent="center">
                    <Button
                      size="medium"
                      onClick={handleAddMore}
                      sx={{
                        width: 90,
                        color: theme.palette.primary.main,
                        border: `1px solid ${theme.palette.primary.main}`,
                        whiteSpace: "nowrap",
                        fontSize: 12,
                      }}
                    >
                      <Stack alignItems="center">
                        <AddIcon fontSize="small" />
                        Add More
                      </Stack>
                    </Button>
                  </Box>
                </>
              )}

              <Box p={3}>
                {formError && <Alert severity="error">{formError}</Alert>}
              </Box>

              <Box sx={buttonContainer}>
                <Button
                  disabled={allAddresses.length === 0}
                  onClick={() => {
                    if (allAddresses.length !== 0) {
                      calculateMoveDistance();
                      updateThisMoveRequest({
                        delivery_details: {
                          determined_delivery_address: true,
                          additional_stops: isAdditionalStops,
                          packagaing_required: null,
                          open_location: null,
                          delivery_addresses: allAddresses,
                        },
                      });
                    } else {
                      setFormError("Please enter your address.");
                    }
                  }}
                  variant="contained"
                  sx={{
                    width: 180,
                    height: 45,
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    color: "#FFFFFF",
                    fontSize: 12,
                    fontWeight: 550,
                    alignSelf: "center",
                    borderRadius: 14,
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Stack>
          </Box>

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddressInfo;
