export const ActionTypes = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  CONSUMER_LOGIN: "CONSUMER_LOGIN",
  PICKUP_CARRIER_LOGIN: "PICKUP_CARRIER_LOGIN",
  DELIVERY_CARRIER_LOGIN: "DELIVERY_CARRIER_LOGIN",
  VERIFY_CONSUMER_OTP: "VERIFY_CONSUMER_OTP",
  VERIFY_PICKUP_CARRIER_OTP: "VERIFY_PICKUP_CARRIER_OTP",
  VERIFY_DELIVERY_CARRIER_OTP: "VERIFY_DELIVERY_CARRIER_OTP",
  GET_CONSUMER_BY_ID: "GET_CONSUMER_BY_ID",
  UPDATE_CONSUMER_BY_ID: "UPDATE_CONSUMER_BY_ID",
  GET_ALL_MOVE_REQUESTS_BY_CONSUMER_ID: "GET_ALL_MOVE_REQUESTS_BY_CONSUMER_ID",
  GET_MOVE_REQUEST_BY_ID: "GET_MOVE_REQUEST_BY_ID",
  UPDATE_MOVE_REQUEST_BY_ID: "UPDATE_MOVE_REQUEST_BY_ID",
  CREATE_MOVE_REQUEST: "CREATE_MOVE_REQUEST",
  CREATE_LOAD_REQUEST: "CREATE_LOAD_REQUEST",
  GET_DELIVERY_CARRIER_AGAINST_ID: "GET_DELIVERY_CARRIER_AGAINST_ID",
  GET_LOAD_REQUEST_AGAINST_DC: "GET_LOAD_REQUEST_AGAINST_DC",
  REGISTER_PICKUP_CARRIER: "REGISTER_PICKUP_CARRIER",
  GET_ALL_LOCAL_CARRIERS: "GET_ALL_LOCAL_CARRIERS",
  GET_ALL_PICKUP_CARRIERS: "GET_ALL_PICKUP_CARRIERS",
  GET_ALL_DELIVERY_CARRIERS: "GET_ALL_DELIVERY_CARRIERS",
  REGISTER_DELIVERY_CARRIER: "REGISTER_DELIVERY_CARRIER",
  REGISTER_PICKUP_CARRIER_WITH_FILES: "REGISTER_PICKUP_CARRIER_WITH_FILES",
  REGISTER_LOCAL_CARRIER: "REGISTER_LOCAL_CARRIER",
  LOCAL_CARRIER_LOGIN: "LOCAL_CARRIER_LOGIN",
  VERIFY_LOCAL_CARRIER_OTP: "VERIFY_LOCAL_CARRIER_OTP",
  GET_LOCAL_CARRIER_AGAINST_ID: "GET_LOCAL_CARRIER_AGAINST_ID",
  REGISTER_DELIVERY_CARRIER_WITH_FILES: "REGISTER_DELIVERY_CARRIER_WITH_FILES",
  REGISTER_LOCAL_CARRIER_WITH_FILES: "REGISTER_LOCAL_CARRIER_WITH_FILES",
  ACTIVATE_LOCAL_CARRIER_STATUS: "ACTIVATE_LOCAL_CARRIER_STATUS",
  ACTIVATE_PICKUP_CARRIER_STATUS: "ACTIVATE_PICKUP_CARRIER_STATUS",
  ACTIVATE_DELIVERY_CARRIER_STATUS: "ACTIVATE_DELIVERY_CARRIER_STATUS",
  CHECK_IF_PICKUP_CARRIER_EXISTS: "CHECK_IF_PICKUP_CARRIER_EXISTS",
  CHECK_IF_LOCAL_CARRIER_EXISTS: "CHECK_IF_LOCAL_CARRIER_EXISTS",
  CHECK_IF_DELIVERY_CARRIER_EXISTS: "CHECK_IF_DELIVERY_CARRIER_EXISTS",
  CONSUMER_REGISTER: "CONSUMER_REGISTER",
  GET_ALL_MOVE_REQUEST: "GET_ALL_MOVE_REQUEST",
  GET_SKIP_TRACE: "GET_SKIP_TRACE",
  GET_MLS: "GET_MLS",
  GET_MLS_RECORDS: "GET_MLS_RECORDS",
  GET_SKIP_TRACE_RECORDS: "GET_SKIP_TRACE_RECORDS",
  RESEND_OTP_FOR_DELIVERY_CARRIER: "RESEND_OTP_FOR_DELIVERY_CARRIER",
  RESEND_OTP_FOR_PICKUP_CARRIER: "RESEND_OTP_FOR_PICKUP_CARRIER",
  RESEND_OTP_FOR_LOCAL_CARRIER: "RESEND_OTP_FOR_LOCAL_CARRIER",
  GET_INVENTORY_FROM_VIDEO: "GET_INVENTORY_FROM_VIDEO",
  GET_MOVE_DISTANCE: "GET_MOVE_DISTANCE",
  UPDATE_ROOM_DETAILS: "UPDATE_ROOM_DETAILS",
  CALCULATE_INVENTORY_VOLUME: "CALCULATE_INVENTORY_VOLUME",
  CREATE_CHECKOUT_SESSION: "CREATE_CHECKOUT_SESSION",
  FETCH_ALL_MOVE_REQUESTS_FOR_ADMIN: "FETCH_ALL_MOVE_REQUESTS_FOR_ADMIN",
  CREATE_ROOM_DETAILS: "CREATE_ROOM_DETAILS",
  AUTO_REDIRECT: "AUTO_REDIRECT",
  ATTACH_MOVE_REQEUEST_WITH_USER: "ATTACH_MOVE_REQEUEST_WITH_USER",
  SEND_DATA_TO_SUPER_MOVE: "SEND_DATA_TO_SUPER_MOVE",
  SEND_DATA_TO_GRANOT_MOVE: "SEND_DATA_TO_GRANOT_MOVE",
};
