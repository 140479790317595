import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateMoveRequestById } from "../../redux/actions/move-request";
import { getInventoryVolume } from "../../redux/actions/room-details";
import ReactGA from 'react-ga4'


const FinalizeFlowModal = ({
  isFinalizeFlowModal,
  setIsFinalizeFlowModal,
  moveRequestId,
  thisMoveRequestId,
  setIsPackaginServiceModal
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => setIsFinalizeFlowModal(true);

  const handleNavigateToPackages = () => {
    navigate(`/packages/${moveRequestId}`);
  };

  
    
      ReactGA.send({
        hitType: "pageview",
        page: "/selfservice/",
        title: "wait or continue move page",
      });
    
  

  return (
    <>
      <Modal
        open={isFinalizeFlowModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "75vw" : "35vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography
            mt={1}
            fontSize={18}
            fontWeight={700}
            color={"#262626"}
            lineHeight={1.2}
          >
         <br /> From here you can self-serve or wait for the carrier to reach you out.
          </Typography>
          <Typography mt={1} fontSize={14} color={"#797979"}>
            To make your moving journey easy we also help you in a few services. You will be shown in the next screens.
          </Typography>
          <Box mt={3} display={"flex"} justifyContent={"center"}>
            <Stack spacing={2}>
              <Button
                onClick={() => {
                    localStorage.setItem('flow-type','complete')
            setIsPackaginServiceModal(true);
                }}
                sx={{
                  width: 240,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  border: "1px solid #6552FF",
                }}
              >
                Continue Move
              </Button>

              <Button
             onClick={()=>{
                localStorage.setItem('flow-type','partial')
                navigate(`/booked-move/${moveRequestId}`)
             }}
             sx={{
                width: 240,
                height: 45,
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: "#FFFFFF",
                fontSize: 12,
                fontWeight: 550,
                border: "1px solid #6552FF",
              }}
              >
              Finish & Wait for Carrier
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FinalizeFlowModal;
