import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Alert, Stack, useMediaQuery, useTheme } from "@mui/material";
import placesApiKey from "../utils/Google-Places-API-Key";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomCircularProgress } from "../circularProgress";
import SecurityIcon from "@mui/icons-material/Security";
import { NeedHelp } from "../needHelp";
import ReactGA from 'react-ga4'

const PLACES_API_KEY = placesApiKey;

const HomeOrTownOnlyModal = ({
  homeOnlyModalVisible,
  setHomeOnlyModalVisible,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [address, setAddress] = useState("");
  const [formError, setFormError] = useState("");

  const handleClose = () => setHomeOnlyModalVisible(true);

  const navigateToLocationInfo = async () => {
    if (address !== "") {
      
      setFormError("");

      ReactGA.send({
        hitType:'pageview',
        page:"/start-move/home-only",
        title:`Address selected`
      })
  

      await createMoveRequest({
        id: thisMoveRequest.id,
        home_address: address,
      });
    } else {
      setFormError("Please enter your Address.");
    }
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setAddress(completeAddress);
  };

  return (
    <Box
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "60vw", lg: "60vw" },
        height: isMobile ? "90vh" : undefined,
        borderRadius: "8px",
        overflow: "auto",
        p: 4, // Adding padding for structure
        mt: 5, // Adding margin for positioning
        border: "1px solid #0000001A",
        backgroundColor: "#FBFBFB",
        alignSelf: "center",
        mb: 5,
      }}
    >
      <NeedHelp />

      {/* Modal Header - Adjust as component title */}
      <Stack
        sx={{
          alignItems: isMobile ? "center" : "flex-start",
          overflow: "auto",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"80%"}
        >
          <Stack>
            <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
              Home Address
            </Typography>
            <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
              Fill in all info correctly
            </Typography>
          </Stack>
          <CustomCircularProgress value={3} total={10} />
        </Stack>
      </Stack>
      {/* Loading State */}
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      )}

      {/* Back Button */}
      {/* <Button
        onClick={() => {
          setMoveType("");
          setOutOfStateMoveModalVisible(true); // Adjust to component
        }}
        sx={{ marginTop: 1, marginLeft: 1 }}
      >
        <ArrowBackIcon
          sx={{ color: theme.palette.primary.main }}
          fontSize="large"
        />
      </Button> */}

      {/* Content */}
      <Box sx={{ p: 3 }}>
        <Typography
          mt={1}
          mb={1}
          fontSize={16}
          fontWeight={500}
          color={"#000000B2"}
        >
          What address are you moving from?
        </Typography>

        {/* Google Places Autocomplete */}
        <GooglePlacesAutocomplete
          apiOptions={{ language: "en" }}
          apiKey={PLACES_API_KEY}
          debounce={1000}
          selectProps={{
            styles: {
              input: (provided) => ({
                ...provided,
                color: "#000000",
                borderRadius: "8px",
              }),
              option: (provided) => ({
                ...provided,
                color: "#808080",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "#808080",
              }),
            },
            onChange: handleAddress,
            placeholder: "Enter Address",
          }}
        />

        {/* Why we need the address section */}
        {/* <Box
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
            p: 3,
            mt: 4,
            mb: 5,
            backgroundColor: "#22965F0F",
          }}
        >
          <Stack spacing={2}>
            <Typography fontWeight={600} fontSize={12}>
              Why do we need this information?
            </Typography>
            <Typography color={"#625E5E"} fontSize={12}>
              This information is required to provide you with an accurate quote
              and will not be shared or sold. If you don't feel comfortable
              providing this information, please provide either the zip code
              and/or the city and state you are moving from.
            </Typography>
          </Stack>
        </Box> */}
        <Stack
          p={1}
          spacing={1}
          mt={5}
          width={"90%"}
          direction={"row"}
          alignItems={"center"}
          sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
        >
          <SecurityIcon fontSize="small" sx={{ color: "#0FB69D" }} />
          <Typography fontSize={10}>
            Your info is safe with us We need details to create the perfect plan
            for you
          </Typography>
        </Stack>
      </Box>

      {/* Error Message */}
      <Box p={3}>
        {formError && <Alert severity="error">{formError}</Alert>}
      </Box>

      {/* Next Button */}
      <Stack
        alignSelf={"center"}
        direction={"row"}
        spacing={2}
        p={4}
        justifyContent={"center"}
      >
        <Button
          onClick={() => {
            setMoveType("");
            setOutOfStateMoveModalVisible(true); // Adjust to component
            setHomeOnlyModalVisible(false);
          }}
          sx={{
            width: 120,
            height: 45,
            backgroundColor: "#E9E9EA",
            color: "#808080",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            navigateToLocationInfo();
          }}
          sx={{
            width: 180,
            height: 45,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};

export default HomeOrTownOnlyModal;
