import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

export const GeneratePDFModal = ({
  showPDFModal,
  setShowPDFModal,
  totalCountOfMoves,
  handleDownloadCSV,
  loading,
  pdfMoves,
  getMovesForPdf,
  setPdfOffset,
  pdfOffset,
  setPdfMoves,
}: any) => {
  const intervals = [];
  for (let i = 0; i < totalCountOfMoves; i += 20) {
    intervals.push({
      start: i + 1,
      end: Math.min(i + 20, totalCountOfMoves),
    });
  }
  const handleRadioChange = (offset: number) => {
    setPdfOffset(offset - 1); // Update pdfOffset when a new option is selected
  };
  return (
    <Modal
      open={showPDFModal}
      onClose={() => {
        setPdfOffset(null);
        setPdfMoves([]);
        setShowPDFModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Stack spacing={2}>
          <h3>Select Interval for CSV Generation</h3>
          <div>
            {intervals.map((interval, index) => (
              <div key={index}>
                <input
                  type="radio"
                  name="interval"
                  value={interval.start}
                  onChange={() => handleRadioChange(interval.start)}
                  checked={pdfOffset === interval.start - 1}
                />
                <label>{`${interval.start} - ${interval.end}`}</label>
              </div>
            ))}
          </div>
          <Button
            sx={{ backgroundColor: "#21837B" }}
            variant="contained"
            onClick={() => {
              getMovesForPdf(pdfOffset);
            }}
            disabled={pdfOffset === null} // Disable if no option is selected
          >
            Generate CSV
          </Button>
          <Button
            sx={{ backgroundColor: "#21837B" }}
            variant="contained"
            onClick={() => {
              handleDownloadCSV(pdfMoves);
              setPdfOffset(null);
            }}
            disabled={pdfMoves.length === 0} // Disable if no option is selected
          >
            Download CSV
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
