import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stairs from "../../assets/images/StairsIcon.png";
import Elevator from "../../assets/images/Elevator.png";
import NotAvailable from "../../assets/images/N:A-Icon.png";
import SecurityIcon from "@mui/icons-material/Security";
import ReactGA from 'react-ga4'

import {
  Alert,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { CustomCircularProgress } from "../circularProgress";
import { NeedHelp } from "../needHelp";

const PLACES_API_KEY = `AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8`;

const ApartmentOnlyModal = ({
  isApartmenOnlyModal,
  setIsApartmenOnlyModal,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const notSeelectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: "#F2F2F4 !important",
    color: "#262626",
    fontSize: 12,
    fontWeight: 550,
    border: "1px solid #0000001A",
    borderRadius: "16px",
  };

  const selectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: `#002496 !important`,
    color: "#FDFCFD",
    fontSize: 12,
    fontWeight: 550,
    borderRadius: "16px",
  };
  const [destinationFloor, setDestinationFloor] = useState<any>(null);
  const [isElevator, setIsElevator] = useState("");
  // const [isElevatorAccess, setIsElevatorAccess] = useState(false);
  // const [elevatorType, setElevatorType] = useState("freight");
  const [address, setAddress] = useState("");
  const [formError, setFormError] = useState("");

  const handleClose = () => setIsApartmenOnlyModal(true);

  const handleInputNumber = (event: any, setNumber: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 3;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setNumber(inputValue);
    event.target.value = inputValue;
  };

  const navigateToLocationInfo = async () => {
    if (address === "") {
      setFormError("Please enter your Address.");
      return;
    }

    if (isElevator === "") {
      setFormError("Please select if Elevator or Stairs would be needed.");
      return;
    }

    if (destinationFloor === null) {
      setFormError("Please enter floor number.");
      return;
    }

    setFormError("");

    ReactGA.send({
      hitType:'pageview',
      page:"/start-move/apartment-only",
      title:`Address selected`
    })


    await createMoveRequest({
      id: thisMoveRequest.id,
      apartment: {
        floor_no:
          isElevator === "not-applicable" ? 0 : Number(destinationFloor),
        is_elevator_available: isElevator === "elevator" ? true : false,
        is_elevator_accessible: false,
        elevator_type: "regular",
        apt_address: address,
      },
    });
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;
      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setAddress(completeAddress);
  };

  return (
    <Box
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
        height: isMobile ? "90vh" : "auto",
        p: { xs: 2, sm: 4 }, // Responsive padding for small screens
        mt: { xs: 3, sm: 5 },
        border: "1px solid #0000001A",
        borderRadius: "8px",
        overflow: "auto",
        backgroundColor: "#FBFBFB",
      }}
    >
      <NeedHelp />
      {/* Component Header */}
      <Stack
        sx={{
          alignItems: isMobile ? "center" : "flex-start",
          overflow: "auto",
          mb: 3, // Margin bottom for spacing
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ mb: 2 }}
        >
          <Stack>
            <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
              Apartment Address
            </Typography>
            <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
              Fill in all info correctly
            </Typography>
          </Stack>
          <CustomCircularProgress value={3} total={10} />
        </Stack>
      </Stack>

      {/* Loading State */}
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      )}

      {/* Content */}
      <Grid
        container
        spacing={2}
        flexDirection={isMobile ? "column-reverse" : undefined}
      >
        <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
          <Typography mt={1} mb={1} fontWeight={500} fontSize={16}>
            What address are you moving from?
          </Typography>

          {/* Google Places Autocomplete */}
          <GooglePlacesAutocomplete
            apiOptions={{ language: "en" }}
            apiKey={PLACES_API_KEY}
            debounce={1000}
            selectProps={{
              styles: {
                input: (provided) => ({
                  ...provided,
                  color: "#000000",
                  height: "100%",
                  borderRadius: "8px",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#808080",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#808080",
                }),
              },
              onChange: handleAddress,
              placeholder: "Enter Address",
            }}
          />

          {/* Elevator or Stairs */}
          <Typography mt={4} fontWeight={500} fontSize={16}>
            Stairs or an Elevator needed?
          </Typography>
          <Typography
            fontWeight={400}
            fontSize={14}
            sx={{ fontStyle: "italic" }}
          >
            choose one
          </Typography>
          <Stack mt={2} direction="row" spacing={1}>
            {/* Stairs Button */}
            <Button
              onClick={() => {
                setIsElevator("stairs");
                setDestinationFloor(null);
              }}
              sx={
                isElevator === "stairs"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Stairs}
                  style={{
                    filter:
                      isElevator === "stairs"
                        ? "none"
                        : "invert(100%) brightness(2)",
                    width: 50,
                    height: 50,
                  }}
                  alt="stairs"
                />
                <Typography
                  fontSize={12}
                  color={isElevator === "stairs" ? "#FFFFFF" : "#000000"}
                >
                  Stairs
                </Typography>
              </Stack>
            </Button>

            {/* Elevator Button */}
            <Button
              onClick={() => {
                setIsElevator("elevator");
                setDestinationFloor(null);
              }}
              sx={
                isElevator === "elevator"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Elevator}
                  style={{
                    filter:
                      isElevator === "elevator"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="elevator"
                />
                <Typography
                  fontSize={12}
                  color={isElevator === "elevator" ? "#FFFFFF" : "#000000"}
                >
                  Elevator
                </Typography>
              </Stack>
            </Button>

            {/* Not Applicable Button */}
            <Button
              onClick={() => {
                setIsElevator("not-applicable");
                setDestinationFloor(0);
              }}
              sx={
                isElevator === "not-applicable"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={NotAvailable}
                  style={{
                    filter:
                      isElevator === "not-applicable"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="not-applicable"
                />
                <Typography
                  fontSize={12}
                  color={
                    isElevator === "not-applicable" ? "#FFFFFF" : "#000000"
                  }
                >
                  Not Applicable
                </Typography>
              </Stack>
            </Button>
          </Stack>

          {/* Floor Input */}
          {isElevator !== "not-applicable" && (
            <>
              <Typography
                fontSize={16}
                mt={4}
                fontWeight={500}
                color={"#262626"}
              >
                What floor are you on?
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={destinationFloor || ""}
                size="small"
                onChange={(event) => {
                  handleInputNumber(event, setDestinationFloor);
                }}
                inputProps={{
                  style: {
                    background: "#5A7BFC14",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  marginTop: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5A7BFC59",
                      borderWidth: "1.43",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </>
          )}
        </Grid>

        {/* Optional Side Section (can uncomment if needed) */}
        {/* 
      <Grid item xs={12} sm={5} p={isMobile ? 2 : 4} mt={2}>
        <Stack
          alignItems={"center"}
          p={3}
          sx={{
            background: "#22965F0F",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              width: 80,
              height: 80,
              borderRadius: "40px",
              background: "#D9D9D9",
            }}
          />
          <Typography
            mt={3}
            fontWeight={600}
            fontSize={16}
            textAlign={"center"}
          >
            Why do we need this information?
          </Typography>
          <Typography
            mt={1}
            fontWeight={500}
            fontSize={14}
            textAlign={"center"}
            color={"#625E5E"}
          >
            This information is required to provide you with an accurate quote
            and will not be shared or sold.
          </Typography>
        </Stack>
      </Grid> 
      */}
      </Grid>
      <Stack
        p={1}
        spacing={1}
        mt={5}
        width={"90%"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
      >
        <SecurityIcon fontSize="small" sx={{ color: "#0FB69D" }} />
        <Typography fontSize={10}>
          Your info is safe with us We need details to create the perfect plan
          for you
        </Typography>
      </Stack>
      {/* Error Message */}
      <Box p={3}>
        {formError && <Alert severity="error">{formError}</Alert>}
      </Box>

      {/* Buttons */}
      <Stack p={4} direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() => {
            setMoveType("");
            setOutOfStateMoveModalVisible(true);
            setIsApartmenOnlyModal(false);
          }}
          sx={{
            width: 120,
            height: 45,
            backgroundColor: "#E9E9EA",
            color: "#808080",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Back
        </Button>
        <Button
          onClick={navigateToLocationInfo}
          sx={{
            width: 180,
            height: 45,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};

export default ApartmentOnlyModal;
