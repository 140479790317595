import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SecurityIcon from "@mui/icons-material/Security";
import { useNavigate } from "react-router-dom";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import {
  attachUserWithMoveRequest,
  createThieMoveRequest,
  getAllMoveRequestsByConsumerID,
  getMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import { getConsumerById } from "../../redux/actions/consumer";
import ServiceSelectionModal from "../modals/serviceSelectionModal";
import OutOfStateMoveModal from "../modals/outOfStateMoveModal";
import ApartmentOnlyModal from "../modals/apartmentOnlyModal";
import ComboApartmentStorageModal from "../modals/comboApartmentStorageModal";
import HomeStorageCombo from "../modals/homeStorageCombo";
import HomeOrTownOnlyModal from "../modals/homeOrTownOnlyModal";
import StorageOnlyModal from "../modals/storageOnlyModal";
import moveBeesBackground from "../../assets/images/movebees1.png";
import kruppBackground from "../../assets/images/BACKGROUND_KRUPP.png";
import kruppLandingLogo from "../../assets/images/Krupp-logo (1).jpg";
import moveBeesLandingLogo from "../../assets/images/MoveBees-Logo-HR-Yellow.png";
import MoveRequestDetail from "../moveRequestDetail/moveRequestDetail";
import rockstarBackground from "../../assets/images/rockstarpromovers-bg.png";
import { LandingPage } from "../../pages/landingPage/landingPage";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import landingImage from "../../assets/images/Two men carrying sofa 3.png";
import LogoHeader from "../logo-header/logoHeader";
import ReactGA from 'react-ga4'
import { title } from "process";


const ConsumerDashboard = ({ consumerCanonicalId }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [consumer, setConsumer] = useState<any>();
  const [allMoveRequests, setAllMoveRequests] = useState<any>([]);
  const [thisMoveRequest, setThisMoveRequest] = useState<any>(
    allMoveRequests[0] ? allMoveRequests[0] : null
  );

  const [moveType, setMoveType] = useState("");

  const [isShowAllMoveRequests, setIsShowAllMoveRequests] = useState(true);
  const [isMoveRequestDetail, setIsMoveRequestDetail] = useState<any>(null);

  const [isServiceSelectionModal, setIsServiceSelectionModal] = useState(true);
  const [isApartmenOnlyModal, setIsApartmenOnlyModal] = useState(false);
  const [isHomeStorageComboModal, setIsHomeStorageComboModal] = useState(false);
  const [homeOnlyModalVisible, setHomeOnlyModalVisible] = useState(false);
  const [storageOnlyModalVisible, setStorageOnlyModalVisible] = useState(false);
  const [outOfStateMoveModalVisible, setOutOfStateMoveModalVisible] =
    useState(false);
  const [allowNavigation, setAllowNavigation] = useState(false);
  // useEffect(() => {
  //   const handlePopState = () => {
  //     navigate(0);  // This will reload the current page
  //   };

  //   window.history.pushState(null, document.title, window.location.href);
  //   window.history.pushState(null, document.title, window.location.href);

  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [navigate]);
  const [isBackTrue, setIsBackTrue] = useState(false);
  const [
    comboApartmentStorageModalVisible,
    setComboApartmentStorageModalVisible,
  ] = useState(false);

  const fetchAllMoveReuqests = () => {
    dispatch<any>(getAllMoveRequestsByConsumerID(consumerCanonicalId))
      .then(unwrapResult)
      .then((moveRequests: any) => {
        console.log(moveRequests, "--->alllll");
        if (
          url === "https://production.wemoveai.com" ||
          url === "https://turbotenant.wemoveai.com" ||
          url === "https://sold.wemoveai.com" ||
          url === "https://movebees.wemoveai.com" ||
          url === "https://kruppmoving.wemoveai.com" ||
          url === "https://summitmoving.wemoveai.com" ||
          url === "https://rockstarspropartial.wemoveai.com" ||
          url === "https://rockstarsprocomplete.wemoveai.com" ||
          url === "https://dearmanmoving.wemoveai.com" ||
          url === "https://safewaymoving.wemoveai.com"
        ) {
          if (moveRequests.length !== 0) {
            setThisMoveRequest(moveRequests[0]);
            if (moveRequests[0].location_type !== null) {
              setMoveType(moveRequests[0].location_type);
            }
          }
          setAllMoveRequests([...allMoveRequests, moveRequests[0]]);
        } else if (url === "https://staging.wemoveai.com") {
          setAllMoveRequests(moveRequests);
        }
      });
  };

  const fetchThisConsumer = () => {
    dispatch<any>(getConsumerById(consumerCanonicalId))
      .then(unwrapResult)
      .then((consumer: any) => {
        setConsumer(consumer);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq.home_address !== null) {
      return moveReq.home_address;
    }
    if (moveReq.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }
    if (moveReq.storage !== null) {
      return moveReq.storage?.address;
    }
    if (moveReq.combo_home_storage !== null) {
      return (
        moveReq.combo_home_storage?.home_address.toString() +
        "/" +
        moveReq.combo_home_storage.storage?.address.toString()
      );
    }
    if (moveReq.combo_apartment_storage !== null) {
      return (
        moveReq.combo_apartment_storage?.apartment?.apt_address.toString() +
        "/" +
        moveReq.combo_apartment_storage?.storage?.address.toString()
      );
    }
  };

  const navigateToLastLeft = (moveRequest: any) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      if (moveRequest.location_type === null) {
        setThisMoveRequest(moveRequest);
        setOutOfStateMoveModalVisible(true);
      } else {
        if (
          moveRequest.location_type !== null &&
          moveRequest.location_type === "home-only" &&
          moveRequest.home_address === null
        ) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest);
          setHomeOnlyModalVisible(true);
        } else if (
          moveRequest.location_type !== null &&
          moveRequest.location_type === "storage-only" &&
          moveRequest.storage === null
        ) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest);
          setStorageOnlyModalVisible(true);
        } else if (
          moveRequest.location_type !== null &&
          moveRequest.location_type === "apartment-only" &&
          moveRequest.apartment === null
        ) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest);
          setIsApartmenOnlyModal(true);
        } else if (
          moveRequest.location_type !== null &&
          moveRequest.location_type === "combo-home-storage" &&
          moveRequest.combo_home_storage === null
        ) {
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest);
          setIsHomeStorageComboModal(true);
        } else if (
          moveRequest.location_type !== null &&
          moveRequest.location_type === "combo-apartment-storage" &&
          moveRequest.combo_apartment_storage === null
        ) {
          console.log("falling here------");
          setOutOfStateMoveModalVisible(false);
          setThisMoveRequest(moveRequest);
          setComboApartmentStorageModalVisible(true);
        } else {
          if (
            moveRequest.location_type !== "storage-only" &&
            moveRequest.roomDetails.length === 0
          ) {
            if (isMobile) {
              navigate(`/upload-inventory/${moveRequest.canonical_id}`);
            } else {
              navigate(`/inventory-guidelines/${moveRequest.canonical_id}`);
            }
          } else {
            if (!moveRequest.delivery_details) {
              navigate(`/AddressInfo/${moveRequest.canonical_id}`);
            } else {
              if (
                moveRequest.delivery_details?.delivery_addresses?.length === 0
              ) {
                navigate(`/AddressInfo/${moveRequest.canonical_id}`);
              } else {
                if (
                  moveRequest.delivery_details?.delivery_addresses[0]
                    .delivery_location_type === null
                ) {
                  navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
                } else {
                  if (
                    moveRequest.pickup_date_from === null &&
                    moveRequest.pickup_date_to === null
                  ) {
                    navigate(`/PickupDate/${moveRequest.canonical_id}`);
                  } else {
                    if (moveRequest.delivery_details.open_location === null) {
                      navigate(`/TruckInfo/${moveRequest.canonical_id}`);
                    } else {
                      if (
                        (moveRequest.mover_category === null ||
                          moveRequest.total_bedrooms === null) &&
                        moveRequest.location_type !== "storage-only"
                      ) {
                        navigate(`/boxing-info/${moveRequest.canonical_id}`);
                      } else {
                        if (moveRequest.total_cubic_feet === null) {
                          navigate(`/itemsInfo/${moveRequest.canonical_id}`);
                        } else {
                          if (
                            moveRequest.delivery_details
                              ?.packagaing_required === null &&
                            checkTenant() === true
                          ) {
                            navigate(`/packages/${moveRequest.canonical_id}`);
                          } else {
                            if (
                              moveRequest.user === null &&
                              checkTenant() === true
                            ) {
                              console.log(
                                moveRequest,
                                "------>>>>>>>>>>>>>>>>>"
                              );
                              navigate(
                                `/user-verification/${moveRequest.canonical_id}`
                              );
                            } else {
                              if (
                                moveRequest.user.email_verified === false &&
                                checkTenant() === true
                              ) {
                                navigate(`/login`);
                              } else {
                                if (
                                  moveRequest.initial_deposit === null ||
                                  (moveRequest.contract_signed === null &&
                                    checkTenant() === true)
                                ) {
                                  navigate(
                                    `/move-summary/${moveRequest.canonical_id}`
                                  );
                                } else {
                                  if (
                                    moveRequest.contract_signed === false &&
                                    checkTenant() === true
                                  ) {
                                    navigate(
                                      `/contract/${moveRequest.canonical_id}`
                                    );
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }, 1000);
  };

  const createMoveRequest = (body: any) => {

   
    setIsLoading(true);
    const params = {
      // consumerId: consumer.id,
      body: body,
    };
    console.log("params in createMoveRequest=>", params);
    dispatch<any>(createThieMoveRequest(params))
      .then(unwrapResult)
      .then((res: any) => {
        const moveRequestObject = JSON.stringify(res);
        localStorage.setItem("current-move-request", moveRequestObject);
        localStorage.setItem("move-request-id", res.canonical_id);
        console.log(res.user, "---CREATED NOQWWW->>>>>>");
        if (localStorage.getItem("user-object") && res.user === null) {
          const userObject = localStorage.getItem("user-object");
          if (userObject) {
            const user = JSON.parse(userObject);
            console.log("NOPEEEEEEEE", user);
            const newParams = {
              moveRequestId: res.id,
              userId: user.id,
            };
            dispatch<any>(attachUserWithMoveRequest(newParams))
              .then(unwrapResult)
              .then((move: any) => {
                console.log("MOVE ATTACHED TO USER");
              });
          }
        }
        setThisMoveRequest(res);
        setIsLoading(false);
        console.log(outOfStateMoveModalVisible, "---->>NOT YET UPDATED");
        // if (isServiceSelectionModal === true) {
        //   setOutOfStateMoveModalVisible(true);
        //   setIsServiceSelectionModal(false);
        // } else {
        if (isServiceSelectionModal === true) {
          setIsServiceSelectionModal(false);
          setOutOfStateMoveModalVisible(true);
        } else {
          if (
            outOfStateMoveModalVisible === true &&
            moveType === "apartment-only"
          ) {
            setOutOfStateMoveModalVisible(false);
            setIsApartmenOnlyModal(true);
          } else {
            if (
              outOfStateMoveModalVisible === true &&
              moveType === "combo-apartment-storage"
            ) {
              setOutOfStateMoveModalVisible(false);
              setComboApartmentStorageModalVisible(true);
            } else {
              if (
                outOfStateMoveModalVisible === true &&
                moveType === "combo-home-storage"
              ) {
                setOutOfStateMoveModalVisible(false);
                setIsHomeStorageComboModal(true);
              } else {
                if (
                  outOfStateMoveModalVisible === true &&
                  moveType === "home-only"
                ) {
                  setOutOfStateMoveModalVisible(false);
                  setHomeOnlyModalVisible(true);
                } else {
                  if (
                    outOfStateMoveModalVisible === true &&
                    moveType === "storage-only"
                  ) {
                    console.log("falling hereeeelaksmlkas");
                    setStorageOnlyModalVisible(true);
                    setOutOfStateMoveModalVisible(false);
                  } else {
                    if (res.location_type === "storage-only") {
                      navigate(`/addressInfo/${thisMoveRequest.canonical_id}`);
                    } else {
                      console.log("FINAL HERE IN BRACK----------------");
                      navigate(
                        `/inventory-guidelines/${thisMoveRequest.canonical_id}`
                      );
                    }
                  }
                }
              }
            }
          }
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    console.log(outOfStateMoveModalVisible, "use effectttt");
  }, [outOfStateMoveModalVisible]);

  const handleMoveRequestDetail = (moveRequest: any) => {
    console.log("move req for details=>", moveRequest);
    setIsShowAllMoveRequests(false);
    setIsMoveRequestDetail(moveRequest);
  };
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log(thisMoveRequest.user, "--user");
        const alreadyExists = allMoveRequests.some(
          (request: any) => request.id === thisMoveRequest.id
        );
        if (!alreadyExists) {
          setAllMoveRequests([...allMoveRequests, thisMoveRequest]);
          setThisMoveRequest(thisMoveRequest);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (consumerCanonicalId !== undefined) {
      console.log("consumerid in dash=>", consumerCanonicalId);
      fetchThisConsumer();

      if (allMoveRequests.length === 0) {
        console.log("here im in---");
        fetchAllMoveReuqests();
      }
    } else {
      if (localStorage.getItem("move-request-id")) {
        console.log(
          "move req in local=>",
          localStorage.getItem("move-request-id")
        );
        const moveId = localStorage.getItem("move-request-id");
        fetchMoveRequestById(moveId);
      }
    }

    console.log(localStorage.getItem("user-origin"));

    setTimeout(() => {
      setIsLoading(false);
    }, 1300);
  }, []);

  useEffect(() => {
    if (
      url === "https://production.wemoveai.com" ||
      url === "https://turbotenant.wemoveai.com" ||
      url === "https://sold.wemoveai.com" ||
      url === "https://movebees.wemoveai.com" ||
      url === "https://kruppmoving.wemoveai.com" ||
      url === "https://summitmoving.wemoveai.com" ||
      url === "https://rockstarspropartial.wemoveai.com" ||
      url === "https://rockstarsprocomplete.wemoveai.com" ||
      url === "https://dearmanmoving.wemoveai.com" ||
      url === "https://safewaymoving.wemoveai.com"
    ) {
      if (thisMoveRequest !== null) {
        handleMoveRequestDetail(thisMoveRequest);
      }
    }
  }, [thisMoveRequest]);
  const executeLoader = () => {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {
          setIsLoading(true);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };
  const checkTenant = () => {
    if (
      process.env.REACT_APP_TENANT === "wemove" ||
      process.env.REACT_APP_TENANT === "turbotenant" ||
      process.env.REACT_APP_TENANT === "rockstarspromoverscomplete" ||
      process.env.REACT_APP_TENANT === "sold"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleInputPhoneNumber = (event: any, setPhoneNumber: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 10;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setPhoneNumber(inputValue);
    event.target.value = inputValue;
  };
  const [movePhoneNumber, setMovePhoneNumber] = useState("");
  const currentTenant = process.env.REACT_APP_TENANT;
  const callSupportContact = () => {
    if (currentTenant === "movebees") {
      window.location.href = "tel:+13307528999";
    } else if (currentTenant === "krupp") {
      window.location.href = "tel:+13303254832";
    } else if (currentTenant === "summitmoving") {
      window.location.href = "tel:+13302949246";
    } else if (currentTenant === "rockstarspromoverscomplete") {
      window.location.href = "tel:+13237625668";
    } else if (currentTenant === "rockstarspromoverspartial") {
      window.location.href = "tel:+13237625668";
    } else if (currentTenant === "dearmanmoving") {
      window.location.href = "tel:+18004725706";
    } else if (currentTenant === "royalmovingco") {
      window.location.href = "tel:+18886349582";
    } else {
      window.location.href = "tel:+15613508422";
    }
  };
  return (
    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center" // Centers the logo and text
        width="100vw" // Full width of the viewport
        sx={{ mt: 2 }} // Adds some margin to give spacing from the top
      >
        {currentTenant === "wemove" || currentTenant === "sold" ? (
          <img
            src={logoWeMove}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        ) : currentTenant === "turbotenant" ? (
          <img
            src={TurboTenantLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : currentTenant === "movebees" ? (
          <img
            src={MoveBeesLogo}
            alt=""
            style={{ width: "240px", height: "66px" }}
          />
        ) : currentTenant === "summitmoving" ? (
          <img
            src={SummitMovingLogo}
            alt=""
            style={{ width: "150px", height: "60px" }}
          />
        ) : currentTenant === "krupp" ? (
          <img
            src={KruppLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : (
          <></>
        )}

        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          style={{ marginLeft: "10px" }}
        >
          {currentTenant === "wemove" || currentTenant === "sold"
            ? "WeMove.ai"
            : currentTenant === "turbotenant"
              ? "TurboTenant"
              : currentTenant === "movebees"
                ? "Movebees"
                : currentTenant === "krupp"
                  ? "Krupp Moving"
                  : currentTenant === "summitmoving"
                    ? "Summit Moving"
                    : ""}
        </Typography>
      </Stack> */}
      <LogoHeader />
      {/* Divider after logo */}
      {isMoveRequestDetail !== null &&
        !outOfStateMoveModalVisible &&
        !isApartmenOnlyModal &&
        !homeOnlyModalVisible &&
        !storageOnlyModalVisible &&
        !isHomeStorageComboModal &&
        !comboApartmentStorageModalVisible && (
          <MoveRequestDetail
            moveRequest={isMoveRequestDetail}
            setIsMoveRequestDetail={setIsMoveRequestDetail}
            setIsShowAllMoveRequests={setIsShowAllMoveRequests}
            setMoveRequest={setIsMoveRequestDetail}
            navigateToLastLeft={navigateToLastLeft}
            thisMoveRequest={thisMoveRequest}
            executeLoader={executeLoader}
            isLoading={isLoading}
            checkTenant={checkTenant}
            url={url}
          />
        )}
      {/* {isServiceSelectionModal && (
        <ServiceSelectionModal
          isServiceSelectionModal={isServiceSelectionModal}
          setIsServiceSelectionModal={setIsServiceSelectionModal}
          createMoveRequest={createMoveRequest}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          thisMoveRequest={thisMoveRequest}
        />
      )} */}

      {isShowAllMoveRequests && (
        <Grid mt={2} item xs={12} md={6}>
          <Divider />
          {!outOfStateMoveModalVisible &&
            !homeOnlyModalVisible &&
            !isApartmenOnlyModal &&
            !storageOnlyModalVisible &&
            !comboApartmentStorageModalVisible &&
            !isHomeStorageComboModal && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  width: "100vw", // Full width of the viewport
                  mt: 10,
                }}
              >
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={1}
                  sx={{
                    width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" }, // Responsive width
                    height: { xs: "auto", sm: "auto" }, // Adjust height based on content
                  }}
                >
                  {currentTenant === "movebees" ? (
                    <Stack
                      sx={{
                        position: "relative", // Set the Stack container to relative positioning
                        alignSelf: "center",
                      }}
                    >
                      <img
                        src={moveBeesBackground}
                        alt="Landing"
                        style={{
                          width: "80%", // Image will take up the full width of the Stack
                          height: "auto", // Let the height adjust based on the image's aspect ratio
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                          borderBottomRightRadius: isMobile ? 20 : 0,
                          borderTopRightRadius: isMobile ? 20 : 0,
                          alignSelf: "center",
                        }}
                      />
                      <img
                        src={moveBeesLandingLogo} // Replace `overlayImage` with the source of your overlay image
                        alt="Overlay"
                        style={{
                          position: "absolute", // Position overlay image absolutely within the container
                          top: "50%", // Adjust the position as needed
                          left: "30%", // Center the overlay
                          transform: "translate(-50%, -50%)", // Center the overlay precisely
                          width: "30%", // Adjust the width as needed for the overlay image
                          height: "auto", // Maintain aspect ratio
                        }}
                      />
                    </Stack>
                  ) : currentTenant === "krupp" ? (
                    <Stack
                      sx={{
                        position: "relative", // Set the Stack container to relative positioning
                        alignSelf: "center",
                      }}
                    >
                      <img
                        src={kruppBackground}
                        alt="Landing"
                        style={{
                          width: "80%", // Image will take up the full width of the Stack
                          height: "auto", // Let the height adjust based on the image's aspect ratio
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                          borderBottomRightRadius: isMobile ? 20 : 0,
                          borderTopRightRadius: isMobile ? 20 : 0,
                          alignSelf: "center",
                        }}
                      />
                      <img
                        src={kruppLandingLogo} // Replace `overlayImage` with the source of your overlay image
                        alt="Overlay"
                        style={{
                          position: "absolute", // Position overlay image absolutely within the container
                          top: "50%", // Adjust the position as needed
                          left: "30%", // Center the overlay
                          transform: "translate(-50%, -50%)", // Center the overlay precisely
                          width: "30%", // Adjust the width as needed for the overlay image
                          height: "auto", // Maintain aspect ratio
                        }}
                      />
                    </Stack>
                  ) : currentTenant === "rockstarspromoverspartial" ||
                    currentTenant === "rockstarspromoverscomplete" ? (
                    <img
                      src={rockstarBackground}
                      alt="Landing"
                      style={{
                        width: "60%", // Image will take up the full width of the Stack
                        height: "auto", // Let the height adjust based on the image's aspect ratio
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: isMobile ? 20 : 0,
                        borderTopRightRadius: isMobile ? 20 : 0,
                        alignSelf: "center",
                      }}
                    />
                  ) : currentTenant === "safewaymoving" ? (
                    <img
                      src={
                        "https://safewaymovinginc.com/wp-content/uploads/2024/10/Safeway-Moving-Long-Distance-Movers.png"
                      }
                      alt="Landing"
                      style={{
                        width: "60%", // Image will take up the full width of the Stack
                        height: "auto", // Let the height adjust based on the image's aspect ratio
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: isMobile ? 20 : 0,
                        borderTopRightRadius: isMobile ? 20 : 0,
                        alignSelf: "center",
                      }}
                    />
                  ) : currentTenant === "royalmovingco" ? (
                    <img
                      src={
                        "https://royalmovingco.com/wp-content/uploads/2024/04/banner_hp.jpg"
                      }
                      alt="Landing"
                      style={{
                        width: "60%", // Image will take up the full width of the Stack
                        height: "auto", // Let the height adjust based on the image's aspect ratio
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: isMobile ? 20 : 0,
                        borderTopRightRadius: isMobile ? 20 : 0,
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <img
                      src={landingImage}
                      alt="Landing"
                      style={{
                        width: "80%", // Image will take up the full width of the Stack
                        height: "auto", // Let the height adjust based on the image's aspect ratio
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: isMobile ? 20 : 0,
                        borderTopRightRadius: isMobile ? 20 : 0,
                        alignSelf: "center",
                      }}
                    />
                  )}

                  <Stack
                    marginTop={5}
                    spacing={5}
                    width={"100%"}
                    alignItems={"center"}
                  >
                    <Typography
                      textAlign={"center"}
                      fontWeight={600}
                      fontSize={isMobile ? 24 : 32}
                    >
                      Moving Made Easy
                    </Typography>
                    <Typography fontSize={16} textAlign={"center"}>
                      Get a moving quote and book your move in
                      <Typography
                        textAlign={"center"}
                        fontWeight={600}
                        style={{ backgroundColor: "#FFD41D" }}
                      >
                        under 5 minutes
                      </Typography>
                    </Typography>
                    <Stack width={"80%"} alignItems={"center"} spacing={2}>
                      <Stack
                        spacing={2}
                        width={"100%"}
                        sx={{
                          backgroundColor: "#093D870F",
                          borderRadius: 4,
                          border: "1px solid #093D870F",
                        }}
                        p={2}
                      >
                        <TextField
                          variant="outlined"
                          onChange={(event) => {
                            handleInputPhoneNumber(event, setMovePhoneNumber);
                          }}
                          type="text"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          sx={{ width: "100%", backgroundColor: "#FFFFFF" }}
                          size="small"
                          placeholder="Enter Phone Number"
                          required
                          value={movePhoneNumber}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          disabled={movePhoneNumber.length !== 10}
                          variant="contained"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 13,
                          }}
                          onClick={() => {
                            if (movePhoneNumber) {

                              ReactGA.send({
                                hitType:'pageview',
                                page:"/start-move",
                                title:`phone number given ${movePhoneNumber}`
                              })

                              
                              setOutOfStateMoveModalVisible(true);
                              setIsLoading(true);
                              setTimeout(() => {
                                createMoveRequest({
                                  move_phone_number: movePhoneNumber,
                                  move_type: "Out-of-State Move",
                                });
                              }, 1000);
                            }
                          }}
                        >
                          Start Quote
                        </Button>
                      </Stack>
                      <Typography>Or</Typography>
                      <Button
                        onClick={() => callSupportContact()}
                        sx={{ width: "100%" }}
                        variant="outlined"
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <PhoneInTalkIcon fontSize="small" />
                          <Typography fontSize={12} fontWeight={600}>
                            Contact Us
                          </Typography>
                        </Stack>
                      </Button>
                    </Stack>
                    <Stack
                      p={1}
                      spacing={1}
                      width={"90%"}
                      direction={"row"}
                      alignItems={"center"}
                      sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
                    >
                      <SecurityIcon
                        fontSize="small"
                        sx={{ color: "#0FB69D" }}
                      />
                      <Typography fontSize={10}>
                        Your info is safe with us We need details to create the
                        perfect plan for you
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}

          {url === "https://staging.wemoveai.com" &&
            !outOfStateMoveModalVisible &&
            !isApartmenOnlyModal &&
            !homeOnlyModalVisible &&
            !storageOnlyModalVisible &&
            !comboApartmentStorageModalVisible &&
            !isHomeStorageComboModal && (
              <Stack>
                <Typography
                  sx={{ textDecoration: "underline" }}
                  fontWeight={"600"}
                  fontSize={20}
                  mt={1}
                  mb={1}
                  alignSelf={"center"}
                >
                  MOVE REQUESTS
                </Typography>

                <TableContainer
                  sx={{ width: isMobile ? "85vw" : "100%" }}
                  component={Paper}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead
                      sx={{ backgroundColor: theme.palette.primary.main }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "600", fontSize: 12 }}
                        >
                          Move Order #
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "600", fontSize: 12 }}
                        >
                          Move Details
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ fontWeight: "600", fontSize: 12 }}
                        >
                          Pick-up Date
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "600", fontSize: 12 }}
                        >
                          Move Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allMoveRequests.map((thisRequest: any) => (
                        <TableRow
                          key={thisRequest.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ fontWeight: "600" }} align="center">
                            {thisRequest.move_order_number}
                          </TableCell>
                          <TableCell sx={{ fontWeight: "600" }} align="center">
                            <Button
                              onClick={() => {
                                handleMoveRequestDetail(thisRequest);
                              }}
                            >
                              View Details
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {thisRequest.pickup_date_from
                              ? new Date(
                                  thisRequest.pickup_date_from
                                ).toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })
                              : "NaN"}{" "}
                            -{" "}
                            {thisRequest.pickup_date_to
                              ? new Date(
                                  thisRequest.pickup_date_to
                                ).toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })
                              : "NaN"}
                          </TableCell>

                          <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                            {thisRequest.move_type === null ||
                            thisRequest.location_type === null ||
                            thisRequest.delivery_details === null ||
                            thisRequest.delivery_details?.delivery_addresses
                              ?.length === 0 ||
                            (thisRequest.delivery_details
                              ?.packagaing_required === null &&
                              checkTenant() === true) ||
                            thisRequest.pickup_date_from === null ||
                            thisRequest.pickup_date_to === null ||
                            thisRequest.delivery_details?.open_location ===
                              null ||
                            thisRequest.boxable_items_volume === null ||
                            thisRequest.no_of_boxes_for_boxable_items ===
                              null ||
                            ((thisRequest.initial_deposit === null ||
                              thisRequest.contract_signed === null ||
                              thisRequest.contract_signed === false) &&
                              checkTenant() === true) ? (
                              <Button
                                onClick={() => {
                                  navigateToLastLeft(thisRequest);
                                }}
                                sx={{
                                  borderColor: theme.palette.primary.main,
                                  bgcolor: theme.palette.primary.main,
                                  color: "#FFFFFF",
                                  fontSize: 10,
                                  "&:hover": {
                                    backgroundColor: "#000000",
                                    color: "#FFFFFF",
                                    fontSize: 10,
                                  },
                                }}
                                variant="outlined"
                              >
                                Complete Move
                              </Button>
                            ) : (
                              <Typography fontSize={12}>Completed</Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              onClick={() => {
                setIsLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      )}
      {outOfStateMoveModalVisible &&
        !homeOnlyModalVisible &&
        !isApartmenOnlyModal &&
        !storageOnlyModalVisible &&
        !isHomeStorageComboModal &&
        !comboApartmentStorageModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <OutOfStateMoveModal
                outOfStateMoveModalVisible={outOfStateMoveModalVisible}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                moveType={moveType}
                setMoveType={setMoveType}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setIsServiceSelectionModal={setIsServiceSelectionModal}
              />
            </Stack>
          </Box>
        )}

      {homeOnlyModalVisible &&
        !isApartmenOnlyModal &&
        !storageOnlyModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <HomeOrTownOnlyModal
                homeOnlyModalVisible={homeOnlyModalVisible}
                setHomeOnlyModalVisible={setHomeOnlyModalVisible}
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
                setMoveType={setMoveType}
              />
            </Stack>
          </Box>
        )}

      {isApartmenOnlyModal &&
        !homeOnlyModalVisible &&
        !storageOnlyModalVisible &&
        !comboApartmentStorageModalVisible &&
        !isHomeStorageComboModal &&
        !outOfStateMoveModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <ApartmentOnlyModal
                isApartmenOnlyModal={isApartmenOnlyModal}
                setIsApartmenOnlyModal={setIsApartmenOnlyModal}
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setMoveType={setMoveType}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
              />
            </Stack>
          </Box>
        )}

      {storageOnlyModalVisible &&
        !isApartmenOnlyModal &&
        !homeOnlyModalVisible &&
        !comboApartmentStorageModalVisible &&
        !isHomeStorageComboModal &&
        !outOfStateMoveModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <StorageOnlyModal
                storageOnlyModalVisible={storageOnlyModalVisible}
                setStorageOnlyModalVisible={setStorageOnlyModalVisible}
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setMoveType={setMoveType}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
              />
            </Stack>
          </Box>
        )}

      {comboApartmentStorageModalVisible &&
        !storageOnlyModalVisible &&
        !isApartmenOnlyModal &&
        !homeOnlyModalVisible &&
        !isHomeStorageComboModal &&
        !outOfStateMoveModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <ComboApartmentStorageModal
                comboApartmentStorageModalVisible={
                  comboApartmentStorageModalVisible
                }
                setComboApartmentStorageModalVisible={
                  setComboApartmentStorageModalVisible
                }
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setMoveType={setMoveType}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
              />
            </Stack>
          </Box>
        )}

      {isHomeStorageComboModal &&
        !storageOnlyModalVisible &&
        !isApartmenOnlyModal &&
        !homeOnlyModalVisible &&
        !comboApartmentStorageModalVisible &&
        !outOfStateMoveModalVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                width: { xs: "90%", sm: "80%", md: "80%", lg: "70%" },
                height: { xs: "auto", sm: "auto" },
              }}
            >
              <HomeStorageCombo
                isHomeStorageComboModal={isHomeStorageComboModal}
                setIsHomeStorageComboModal={setIsHomeStorageComboModal}
                createMoveRequest={createMoveRequest}
                thisMoveRequest={thisMoveRequest}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setMoveType={setMoveType}
                setOutOfStateMoveModalVisible={setOutOfStateMoveModalVisible}
              />
            </Stack>
          </Box>
        )}
    </Stack>
  );
};

export default ConsumerDashboard;
