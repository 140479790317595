import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import Stairs from "../../assets/images/StairsIcon.png";
import Elevator from "../../assets/images/Elevator.png";
import NotAvailable from "../../assets/images/N:A-Icon.png";
import SecurityIcon from "@mui/icons-material/Security";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Alert from "@mui/material/Alert";
import placesApiKey from "../utils/Google-Places-API-Key";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomCircularProgress } from "../circularProgress";
import { NeedHelp } from "../needHelp";
import ReactGA from 'react-ga4'

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: "100%",
  },
];

const storageSizeDropDown = [
  {
    label: "5x5x8",
    value: 200,
  },
  {
    label: "5x10x8",
    value: 400,
  },
  {
    label: "5x15x8",
    value: 600,
  },
  {
    label: "10x10x8",
    value: 800,
  },
  {
    label: "10x15x8",
    value: 1200,
  },
  {
    label: "10x20x8",
    value: 1600,
  },
  {
    label: "10x25x8",
    value: 2000,
  },
  {
    label: "10x30x8",
    value: 2400,
  },
  {
    label: "20x20x8",
    value: 3200,
  },
];

const PLACES_API_KEY = placesApiKey;

const ComboApartmentStorageModal = ({
  comboApartmentStorageModalVisible,
  setComboApartmentStorageModalVisible,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const notSeelectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: "#F2F2F4 !important",
    color: "#262626",
    fontSize: 12,
    fontWeight: 550,
    border: "1px solid #0000001A",
    borderRadius: "16px",
  };

  const selectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: `#002496 !important`,
    color: "#FDFCFD",
    fontSize: 12,
    fontWeight: 550,
    borderRadius: "16px",
  };
  const handleClose = () => setComboApartmentStorageModalVisible(true);

  const valueText = (value: number) => {
    return `${value}%`;
  };

  const [destinationFloor, setDestinationFloor] = useState<any>(null);
  const [storageSize, setStorageSize] = useState<any>("");
  const [isElevator, setIsElevator] = useState("");
  // const [isElevatorAccess, setIsElevatorAccess] = useState(false);
  // const [elevatorType, setElevatorType] = useState("freight");
  const [apartmentAddress, setApartmentAddress] = useState("");

  const [storageDestinationFloor, setStorageDestinationFloor] =
    useState<any>(null);
  const [isStorageElevator, setIsStorageElevator] = useState("");
  const [storageAddress, setStorageAddress] = useState("");
  const [storageZipCode, setStorageZipCode] = useState("");
  const [storageFilled, setStorageFilled] = useState<any>(70);

  const [formError, setFormError] = useState("");

  const handleStorageFilled = (event: any) => {
    setStorageFilled(event.target.value);
  };

  const handleZipCodeChange = (event: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 5;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setStorageZipCode(inputValue);
    event.target.value = inputValue;
  };

  const handleInputNumber = (event: any, setNumber: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 7;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setNumber(inputValue);
    event.target.value = inputValue;
  };

  const navigateToLocationInfo = async () => {
    if (apartmentAddress === "") {
      setFormError("Please enter your Apartment Address.");
      return;
    }

    if (isElevator === "") {
      setFormError(
        "Please select if Elevator or Stairs would be needed for Apartment."
      );
      return;
    }

    if (destinationFloor === null) {
      setFormError("Please enter Apartment floor number.");
      return;
    }

    if (storageSize === "") {
      setFormError("Please select a Storage size.");
      return;
    }

    if (storageAddress === "") {
      setFormError("Please enter your Storage Address.");
      return;
    }

    if (storageZipCode === "") {
      setFormError("Please enter your Storage Zip code.");
      return;
    }

    if (isStorageElevator === "") {
      setFormError(
        "Please select if Elevator or Stairs would be needed for Storage."
      );
      return;
    }

    if (storageDestinationFloor === null) {
      setFormError("Please enter Storage floor number.");
      return;
    }

    setFormError("");


    ReactGA.send({
      hitType:'pageview',
      page:"/start-move/combo-apartment-storage",
      title:`Address selected`
    })


    await createMoveRequest({
      id: thisMoveRequest.id,
      combo_apartment_storage: {
        apartment: {
          floor_no:
            isElevator === "not-applicable" ? 0 : Number(destinationFloor),
          is_elevator_available: isElevator === "elevator" ? true : false,
          is_elevator_accessible: false,
          elevator_type: "regular",
          apt_address: apartmentAddress,
        },
        storage: {
          storage_size: Number(storageSize.target.value.value),
          storage_filled: Number(storageFilled),
          zip_code: storageZipCode,
          address: storageAddress,
          floor_no:
            isStorageElevator === "not-applicable"
              ? 0
              : Number(storageDestinationFloor),
          is_elevator_accessible:
            isStorageElevator === "elevator" ? true : false,
          are_stairs_present: isStorageElevator === "stairs" ? true : false,
        },
      },
    });
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setApartmentAddress(completeAddress);
  };

  const handleStorageAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setStorageZipCode(zip);
    setStorageAddress(completeAddress);
  };

  return (
    <Box
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
        height: isMobile ? "90vh" : "auto",
        p: { xs: 2, sm: 4 },
        mt: { xs: 3, sm: 5 },
        border: "1px solid #0000001A",
        borderRadius: "8px",
        overflow: "auto",
        backgroundColor: "#FBFBFB",
      }}
    >
      <NeedHelp />
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography fontSize={isMobile ? 18 : 24} fontWeight={600}>
          Combo Apartment-Storage
        </Typography>
        <CustomCircularProgress value={3} total={10} />
      </Stack>
      <Grid item xs={12} sm={7} p={isMobile ? 2 : 4}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ mb: 2 }}
        >
          <Stack>
            <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
              Apartment Address
            </Typography>
            <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
              Fill in all info correctly
            </Typography>
          </Stack>
        </Stack>

        <Typography mt={1} fontSize={14} fontWeight={500} color={"#262626"}>
          What address are you moving from?
        </Typography>

        <GooglePlacesAutocomplete
          apiOptions={{
            language: "en",
          }}
          minLengthAutocomplete={1}
          apiKey={PLACES_API_KEY}
          debounce={1000}
          selectProps={{
            styles: {
              input: (provided) => ({
                ...provided,
                color: "#000000",
                height: "100%",
                borderRadius: "8px",
              }),
              option: (provided) => ({
                ...provided,
                color: "#808080",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "#808080",
              }),
            },
            onChange: handleAddress,
            placeholder: "Enter Address",
          }}
        />

        <Typography mt={2} fontSize={14}>
          Are Stairs or an Elevator?
        </Typography>

        <Typography fontWeight={400} fontSize={14} sx={{ fontStyle: "italic" }}>
          Choose one
        </Typography>

        <Stack mt={2} direction="row" spacing={1}>
          <Button
            onClick={() => {
              setIsElevator("stairs");
              setDestinationFloor(null);
            }}
            sx={
              isElevator === "stairs"
                ? selectedButtonStyle
                : notSeelectedButtonStyle
            }
          >
            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
              <img
                src={Stairs}
                style={{
                  filter:
                    isElevator === "stairs"
                      ? "none"
                      : "invert(100%) brightness(2)",
                  width: 50,
                  height: 50,
                }}
                alt="stairs"
              />
              <Typography
                fontSize={12}
                color={isElevator === "stairs" ? "#FFFFFF" : "#000000"}
              >
                Stairs
              </Typography>
            </Stack>
          </Button>

          <Button
            onClick={() => {
              setIsElevator("elevator");
              setDestinationFloor(null);
            }}
            sx={
              isElevator === "elevator"
                ? selectedButtonStyle
                : notSeelectedButtonStyle
            }
          >
            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
              <img
                src={Elevator}
                style={{
                  filter:
                    isElevator === "elevator"
                      ? "invert(100%) brightness(2)"
                      : "none",
                  width: 50,
                  height: 50,
                }}
                alt="elevator"
              />
              <Typography
                fontSize={12}
                color={isElevator === "elevator" ? "#FFFFFF" : "#000000"}
              >
                Elevator
              </Typography>
            </Stack>
          </Button>

          <Button
            onClick={() => {
              setIsElevator("not-applicable");
              setDestinationFloor(0);
            }}
            sx={
              isElevator === "not-applicable"
                ? selectedButtonStyle
                : notSeelectedButtonStyle
            }
          >
            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
              <img
                src={NotAvailable}
                style={{
                  filter:
                    isElevator === "not-applicable"
                      ? "invert(100%) brightness(2)"
                      : "none",
                  width: 50,
                  height: 50,
                }}
                alt="not-applicable"
              />
              <Typography
                fontSize={12}
                color={isElevator === "not-applicable" ? "#FFFFFF" : "#000000"}
              >
                Not Applicable
              </Typography>
            </Stack>
          </Button>
        </Stack>

        {isElevator !== "not-applicable" && (
          <>
            <Stack mt={2}>
              <Typography fontSize={14} color={"#262626"}>
                What floor are you on?
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                value={destinationFloor || ""}
                size="small"
                onChange={(event) => {
                  handleInputNumber(event, setDestinationFloor);
                }}
                inputProps={{
                  style: {
                    background: "#5A7BFC14",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  marginTop: 1,
                  width: 220,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5A7BFC59",
                      borderWidth: "1.43",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Stack>
          </>
        )}
      </Grid>

      {/* Storage Info */}
      <Grid item xs={12} sm={5} p={isMobile ? 2 : 4} mt={2}>
        <Stack>
          <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
            Storage Address
          </Typography>
          <Typography mt={2} fontSize={14}>
            What is the size of the storage facility?
          </Typography>
          <Stack
            alignItems={"center"}
            marginTop={2}
            direction={"row"}
            spacing={1}
          >
            <FormControl sx={{ minWidth: 150 }} size="small">
              <InputLabel id="demo-select-small-label">
                {storageSize !== ""
                  ? storageSize.target.value.label
                  : "Storage Size"}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={""}
                label="Storage Size"
                onChange={(e) => {
                  setStorageSize(e);
                }}
                required
              >
                {storageSizeDropDown.map((size: any) => (
                  <MenuItem key={size.value} value={size}>
                    {size.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Stack sx={{ marginTop: 2 }}>
          <Typography fontSize={14}>How full is your storage unit?</Typography>

          <Stack spacing={1} alignItems={"flex-start"}>
            <Typography fontStyle={"italic"} fontSize={12} color={"#262626"}>
              drag to use
            </Typography>
            <Box sx={{ width: isMobile ? 230 : 450 }}>
              <Slider
                sx={{ width: isMobile ? 230 : 450 }}
                aria-label="Always visible"
                defaultValue={70}
                getAriaValueText={valueText}
                step={10}
                onChange={(val) => {
                  handleStorageFilled(val);
                }}
                marks={marks}
                valueLabelDisplay="on"
              />
            </Box>
          </Stack>
        </Stack>

        <Stack sx={{ marginTop: 2 }}>
          <Typography fontSize={14} fontWeight={500}>
            What is the storage facility address?
          </Typography>

          <Stack spacing={1} mt={2} direction={isMobile ? "column" : "row"}>
            <GooglePlacesAutocomplete
              apiOptions={{ language: "en" }}
              minLengthAutocomplete={1}
              apiKey={PLACES_API_KEY}
              debounce={500}
              selectProps={{
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: "#000000",
                    height: "100%",
                    width: 150,
                    borderRadius: "8px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                },
                onChange: handleStorageAddress,
                placeholder: "Enter Address",
              }}
            />

            <TextField
              variant="outlined"
              type="text"
              label="Zip Code"
              size="small"
              value={storageZipCode}
              onChange={(e) => handleZipCodeChange(e)}
              inputProps={{
                style: {
                  background: "#5A7BFC14",
                  borderRadius: "8px",
                },
              }}
              sx={{
                mt: 1,
                width: { xs: "100%", sm: 220 },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#5A7BFC59",
                    borderWidth: "1.43",
                    borderRadius: "8px",
                  },
                },
              }}
              required
            />
          </Stack>
        </Stack>

        <Stack sx={{ marginTop: 2 }}>
          <Typography mt={2} fontWeight={400} fontSize={14}>
            Are Stairs or an Elevator?
          </Typography>

          <Typography
            fontWeight={400}
            fontSize={14}
            sx={{ fontStyle: "italic" }}
          >
            choose one
          </Typography>

          <Stack mt={2} direction="row" spacing={1}>
            <Button
              onClick={() => {
                setIsStorageElevator("stairs");
                setStorageDestinationFloor(null);
              }}
              sx={
                isStorageElevator === "stairs"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Stairs}
                  style={{
                    filter:
                      isStorageElevator === "stairs"
                        ? "none"
                        : "invert(100%) brightness(2)",
                    width: 50,
                    height: 50,
                  }}
                  alt="stairs"
                />
                <Typography
                  fontSize={12}
                  color={isStorageElevator === "stairs" ? "#FFFFFF" : "#000000"}
                >
                  Stairs
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsStorageElevator("elevator");
                setStorageDestinationFloor(null);
              }}
              sx={
                isStorageElevator === "elevator"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Elevator}
                  style={{
                    filter:
                      isStorageElevator === "elevator"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="elevator"
                />
                <Typography
                  fontSize={12}
                  color={
                    isStorageElevator === "elevator" ? "#FFFFFF" : "#000000"
                  }
                >
                  Elevator
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsStorageElevator("not-applicable");
                setStorageDestinationFloor(0);
              }}
              sx={
                isStorageElevator === "not-applicable"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={NotAvailable}
                  style={{
                    filter:
                      isStorageElevator === "not-applicable"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="not-applicable"
                />
                <Typography
                  fontSize={12}
                  color={
                    isStorageElevator === "not-applicable"
                      ? "#FFFFFF"
                      : "#000000"
                  }
                >
                  Not Applicable
                </Typography>
              </Stack>
            </Button>
          </Stack>

          {isStorageElevator !== "not-applicable" && (
            <>
              <Typography
                mt={2}
                fontSize={14}
                fontWeight={400}
                color={"#262626"}
              >
                What floor is your storage on?
              </Typography>

              <TextField
                variant="outlined"
                value={storageDestinationFloor || ""}
                size="small"
                onChange={(event) => {
                  handleInputNumber(event, setStorageDestinationFloor);
                }}
                inputProps={{
                  style: {
                    background: "#5A7BFC14",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  marginTop: 1,
                  width: 220,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5A7BFC59",
                      borderWidth: "1.43",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </>
          )}
        </Stack>
      </Grid>

      <Stack
        p={1}
        spacing={1}
        mt={5}
        width={"90%"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
      >
        <SecurityIcon fontSize="small" sx={{ color: "#0FB69D" }} />
        <Typography fontSize={10}>
          Your info is safe with us. We need details to create the perfect plan
          for you.
        </Typography>
      </Stack>

      <Box p={3}>
        {formError && <Alert severity="error">{formError}</Alert>}
      </Box>

      <Stack p={4} direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() => {
            setMoveType("");
            setOutOfStateMoveModalVisible(true);
            setComboApartmentStorageModalVisible(false);
          }}
          sx={{
            width: 120,
            height: 45,
            backgroundColor: "#E9E9EA",
            color: "#808080",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Back
        </Button>
        <Button
          onClick={navigateToLocationInfo}
          sx={{
            width: 180,
            height: 45,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};

export default ComboApartmentStorageModal;
