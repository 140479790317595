import { Button, Stack, Typography } from "@mui/material";

export const NeedHelp = () => {
  const currentTenant = process.env.REACT_APP_TENANT;
  const callSupportContact = () => {
    if (currentTenant === "movebees") {
      window.location.href = "tel:+13307528999";
    } else if (currentTenant === "krupp") {
      window.location.href = "tel:+13303254832";
    } else if (currentTenant === "summitmoving") {
      window.location.href = "tel:+13302949246";
    } else if (currentTenant === "rockstarspromoverscomplete") {
      window.location.href = "tel:+13237625668";
    } else if (currentTenant === "rockstarspromoverspartial") {
      window.location.href = "tel:+13237625668";
    } else if (currentTenant === "dearmanmoving") {
      window.location.href = "tel:+14079040824";
    } else if (currentTenant === "safewaymoving") {
      window.location.href = "tel:+18886349582";
    } else if (currentTenant === "royalmovingco") {
      window.location.href = "tel:+18886349582";
    } else {
      window.location.href = "tel:+15613508422";
    }
  };
  return (
    <Button
      onClick={() => {
        callSupportContact();
      }}
      sx={{
        backgroundColor: "#E4F0FF",
        alignItems: "center",
        height: 28,
        justifyContent: "center",
        width: 180,
        borderRadius: 12,
        my: 1,
      }}
    >
      <Typography fontSize={14} color={"#0257CF"}>
        Need help? Call us
      </Typography>
    </Button>
  );
};
